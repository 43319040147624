import React from "react"
import { blogs } from "../../content/Blogs"
import ContactSection from "../../components/common/ContactSection"
import InnerBlogPage from "../../components/molecules/InnerBlogPage"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const slug = `how-to-apply-for-sda-housing-via-ndis`;

const blogPost = blogs.find(post => post.slug === slug) // Find the blog post by slug

const TaxFileNumberHowDoIApplyForIt = () => (
  <Layout>
    <Seo title="How to Apply for SDA Housing via NDIS: Step-by-Step Guide" />
    <Seo description="Learn how to apply for SDA housing through NDIS with this step-by-step guide. Understand eligibility, application steps, and tips for a successful SDA request." />

    <InnerBlogPage post={blogPost} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default TaxFileNumberHowDoIApplyForIt
